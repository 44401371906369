import { Box, Grid, Modal, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useEffect, useState } from 'react'
import { forkJoin } from 'rxjs'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileService } from '../../modules/files/services/FileService'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'
import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import { ThirdStep } from './ThirdStep'
import { IGV } from './IGV'
import { ProjectAnalysis } from 'modules/projects/models/Project'
import { useLocation } from '@reach/router'
import { URL_PGSEC_TEST } from 'routes/routes-constants'
import {FourthStep} from "./FourthStep";
import { Results } from './Results'

const steps = ['projects', 'informativeness', 'PGT-M', 'results']

export type FileType = {
  id: string
  name: string
}

export type InformativeSNP = {
  upstream: number,
  intrangenic: number,
  downstream: number
}

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
export const Form = () => {
  const styles = commonStyles()
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [fileTypes, setFileTypes] = useState<FileType[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const increaseStep = () => setCurrentStep(currentStep + 1)
  const decreaseStep = () => setCurrentStep(currentStep - 1)
  const [project, setProject] = useState<ProjectAnalysis>()
  const [patientInfo, setPatientInfo] = useState<InformativeSNP>()
  const [partnerInfo, setPartnerInfo] = useState<InformativeSNP>()
  const location = useLocation()
  const [dataProject, setData] = useState<any>()
  const [viewResult, setIsViewResult] = useState<boolean>(false)

  useEffect(()=> {
    if (location.pathname === URL_PGSEC_TEST) {
      setCurrentStep(0)
      setIsViewResult(false)
    }
  }, [location])

  const deleteFiles = (e: FormEvent) => {
    e.preventDefault()
    forkJoin(fileTypes.map((f) => fileService.delete(f.id))).subscribe(() => {
      setOpen(false)
      setCurrentStep(0)
    })
  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setOpen(false),
    },
  ]

   return (
     <Grid container spacing={2}>
       <Grid item xs={12}>
         <Stepper activeStep={currentStep}>
           {steps.map((label) => (
             <Step key={label}>
               <StepLabel>{t(label)}</StepLabel>
             </Step>
           ))}
         </Stepper>
       </Grid>
 
       {currentStep === 0  && !viewResult && (
         <FirstStep
           increaseStep={increaseStep}
           files={setFileTypes}
           handleSelectProject={setProject}
           project={project}
         />
       )}
 
       {currentStep === 1  && !viewResult && (
         <SecondStep
           increaseStep={increaseStep}
           decreaseStep={decreaseStep}
           setData={setData}
           setIsViewResult={setIsViewResult}
           deleteFiles={() => setOpen(true)}
           project={project}
           setProject={setProject}
           currentStep={currentStep}
           patientInf = {setPatientInfo}
           partnerInf = {setPartnerInfo}
         />
       )}
 
       {currentStep === 2 && !viewResult && (
         <ThirdStep
           increaseStep={increaseStep}
           decreaseStep={decreaseStep}
           setData={setData}
           setIsViewResult={setIsViewResult}
           deleteFiles={() => setOpen(true)}
           project={project}
           setProject={setProject}
           currentStep={currentStep}
         />
       )}
 
       {currentStep === 3 && !viewResult&& (
         <FourthStep 
           increaseStep={increaseStep} 
           project={project}
           decreaseStep={decreaseStep} 
           deleteFiles={() => setOpen(true)}          
           patientInfo={patientInfo}
           partnerInfo={partnerInfo}/>
       )}
 
       {viewResult && (
         <Results
           data={dataProject}
           project={project}
           return={setIsViewResult}
           setProject={setProject}
           currentStep={currentStep}
         />
       )}
       
       <Modal open={open}>
         <Box className={styles.modal}>
           <form onSubmit={deleteFiles}>
             <Box mt={2} textAlign={'justify'}>
               {t('cancelReceptiveness')}
             </Box>
             <FormActions actions={actionsDelete} message={t('confirm')} />
           </form>
         </Box>
       </Modal>
     </Grid>
   )
 }
 