import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Head} from 'components/table/Head'
import {AppTable, Field} from 'components/table'
import {Body} from 'components/table/Body'
import {Actions} from 'components/table/types'
import uploadIcon from '../../assets/table-icons/upload-icon.svg'
import {GenericTooltip} from 'components/generic-tooltip'
import {File as F, FileDTO} from '../../modules/files/models/File'
import {dataToBase64, dataToBase642, downloadFile} from 'common/files/file'
import {v4 as uuidv4, NIL as nullId} from 'uuid'
import {getAuthContainer} from 'container/auth-modules'
import {AuthService} from 'modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from 'modules/auth'
import styles from './SecondStep.module.css'
import {getIndividualContainer} from 'container/individual-module'
import {IndividualService} from 'modules/individuals/services/IndividualService'
import {INDIVIDUAL_SERVICE_KEY} from 'modules/individuals'
import {emptyIndividualDTO, Individual, IndividualDTO, Informativity,} from 'modules/individuals/models/Individual'
import {Individuals, individualsTitle} from 'modules/individuals/enums/Individuals'
import {Query, QueryParam} from 'common/api/Query'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {commonStyles} from '../../common/styles/Styles'
import {convertToProjectDTO, ProjectAnalysis} from 'modules/projects/models/Project'
import {getProjectContainer} from 'container/project-module'
import {ProjectService} from 'modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from 'modules/projects'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import {Modal} from '@mui/material'
import {ModalPreview} from 'components/modals/modalPreview/ModalPreview'
import CircularProgress from '@mui/material/CircularProgress'
import {FinalStatus, Status, statusTitle} from '../../modules/individuals/enums/Status'
import {ChildGenderPGD, childGendersPGD} from '../../modules/users/enums/GenderType'
import {COLOR_CLINICS} from 'routes/color-constants'
import {getFileContainer} from 'container/file-module'
import {FileService} from 'modules/files/services/FileService'
import {FILE_SERVICE_KEY} from 'modules/files'
import {firstValueFrom} from 'rxjs'
import {customOrder} from 'modules/users/enums/RoleType'
import {ExpandMore} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import {IGV} from "./IGV";
import { InformativeSNP } from './Form'
import { Permission } from 'common/enums/Permissions'
import { getPatientContainer } from 'container/patient-module'
import { PatientService } from 'modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patients'

const DEFAULT_ROW_KEY = 'id'


type SecondStepProps = {
    increaseStep: () => void
    decreaseStep: () => void
    deleteFiles: () => void
    setData: (data: any) => void
    setIsViewResult: (bool: boolean) => void
    project?: ProjectAnalysis
    setProject: (s: ProjectAnalysis) => void
    currentStep: number
    patientInf: (inf: InformativeSNP) => void
    partnerInf: (inf: InformativeSNP) => void
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const individualService = getIndividualContainer().get<IndividualService>(INDIVIDUAL_SERVICE_KEY)
const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)


interface File2 extends FileDTO {
    individual: Individuals
}

export const SecondStep = (props: SecondStepProps) => {
    const {t} = useTranslation()
    const [individuals, setIndividuals] = useState<Individual[]>([])
    const individualOptions: any[] = [
        Individuals.FEMALE_MOTHER,
        Individuals.FEMALE_FATHER,
        Individuals.MALE_MOTHER,
        Individuals.MALE_FATHER,
        Individuals.FEMALE,
        Individuals.MALE,
        Individuals.CHILD,
        '',
    ]
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const statusOptions = [Status.NonCarrier, Status.Carrier, Status.Affected, Status.Null]
    const [lastAdded, setLastAdded] = useState<IndividualDTO>(emptyIndividualDTO(props.project?.id || ''))
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const fileInputRef2 = useRef<HTMLInputElement | null>(null)
    const fileInputRefBAMBAI = useRef<HTMLInputElement | null>(null);
    const [modalDeleteOpened, setModalDeleteOpened] = useState<boolean>(false)
    const [modalReplaceOpened, setModalReplaceOpened] = useState<boolean>(false)
    const [currentIndividual, setCurrentIndividual] = useState<Individual>()
    const [informativities, setInformativities] = useState<Informativity[]>([])
    const [project, setProject] = useState<ProjectAnalysis | undefined>(props.project)
    const [projectName, ] = useState<String>(props.project?.name || '')
    const [modalPreviewOpened, setModalPreviewOpened] = useState<boolean>(false)
    const styles2 = commonStyles()
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [data, setData] = useState<any>()
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [isViewData, setIsViewData] = useState<boolean>(true)
    const [modalSaveInformativityOpened, setModalSaveInformativityOpened] = useState<boolean>(false)
    const [nameInformativity, setNameInformativity] = useState<string>('')
    const [bamFiles, setBamFiles] = useState<File2[]>([])
    const [isLoadingBamFiles, setIsLoadingBamFiles] = useState<boolean>(true)
    const [isLoadingBamFiles2, setIsLoadingBamFiles2] = useState<boolean>(false)
    const [currentFileIndividual, setCurrentFileIndividual] = useState<Individuals>()
    const [err, setErr] = useState<string>()
    const [open, setOpen] = useState<boolean>(false)
    const [childIndividual, setChildIndividual] = useState<IndividualDTO>()
    const [fatherStatus, setFatherStatus] = useState<Status>()
    const [motherStatus, setMotherStatus] = useState<Status>()
    const [modalChildOriginAffected, setModalChildOriginAffected] = useState<boolean>(false)
    
    const [childFinalStatus, setChildFinalStatus] = useState<FinalStatus>()




    



    const refreshData = async () => {
        setIsLoadingBamFiles(true);
        setIsLoadingBamFiles2(true);
        let auxBam: File2[] = [];
        const promises = individuals.map(async (ind) => {
            let baiFile: File2 = {
                id: uuidv4(),
                name: '',
                data: '',
                size: 0,
                mimeType: '',
                extension: '.bai',
                ownerID: '',
                individual: ind.individual,
                pgType: ''
            };
            if (ind.baiFileID != '') {
                let auxBaiFile = await firstValueFrom(fileService.getBAIModel(ind.baiFileID));
                if (auxBaiFile) {
                    baiFile = {
                        id: auxBaiFile.id,
                        name: auxBaiFile.name,
                        data: auxBaiFile.data,
                        size: auxBaiFile.size,
                        mimeType: auxBaiFile?.mimeType,
                        extension: auxBaiFile?.extension,
                        ownerID: auxBaiFile?.ownerID,
                        individual: ind.individual,
                        pgType: ''
                     
                    };
                }
                auxBam.push(baiFile);
            }


                        // Obtener el archivo BIM con lógica de reintentos
                        let bimFile: File2 = {
                            id: uuidv4(),
                            name: '',
                            data: '',
                            size: 0,
                            mimeType: '',
                            extension: 'bam',
                            ownerID: '',
                            individual: ind.individual,
                            pgType: ''
                        };
                
                        if (ind.bimFileID !== '') {
                            let auxBimFile = null;
                            let attempts = 0;
                            
                            // Intentar hasta 3 veces si auxBimFile es null
                            while (attempts < 4 && !auxBimFile) {
                                try {
                                    auxBimFile = await firstValueFrom(fileService.getBIMModel(ind.bimFileID));
                                    
                                    if (auxBimFile) {
                                        bimFile = {
                                            id: auxBimFile.id,
                                            name: auxBimFile.name,
                                            data: auxBimFile.data,
                                            size: auxBimFile.size,
                                            mimeType: auxBimFile?.mimeType,
                                            extension: auxBimFile?.extension,
                                            ownerID: auxBimFile?.ownerID,
                                            individual: ind.individual,
                                            pgType: ''
                                        };
                                    }
                                } catch (error) {
                                    console.error(`Error al obtener BIM File en el intento ${attempts + 1}:`, error);
                                }
                
                                // Esperar 1 segundo antes de intentar de nuevo si el archivo BIM aún es null
                                if (!auxBimFile) {
                                    await new Promise(resolve => setTimeout(resolve, 5000));
                                }
                
                                attempts++;
                            }
                
                            // Si después de 3 intentos no se consigue, no agregamos el archivo BIM
                            if (auxBimFile) {
                                auxBam.push(bimFile);
                            } 
                        }
                
                        // Si no hay archivo BIM y tampoco hay archivo BAI, agregamos el archivo BAI
                        if (ind.bimFileID === '' && ind.baiFileID === '') {
                            auxBam.push(baiFile);
                        }

        });

        await Promise.all(promises);
    
        // Ordenar y establecer los archivos BAM en el estado
        setBamFiles(auxBam.sort((a, b) => customOrder[a.individual] - customOrder[b.individual]));
        setIsLoadingBamFiles(false);
        setIsLoadingBamFiles2(false);
    };

    useEffect(() => {
        if (individuals.length == 0) {
            setIsLoadingBamFiles(false)
            return
        }

        if (!isLoadingBamFiles) {
            return
        }

        refreshData()
    }, [individuals, isLoadingBamFiles])


    const openSaveInformativityModal = () => {
        let informativityName = informativities.find((i) => i.id == project?.informativityID)?.name
   
         if(informativityName == "" || informativityName == undefined ||informativityName == "New"){
             setModalSaveInformativityOpened(true)}
             else{
                saveInformativity()
             }
    }

    const saveInformativity = () => {
        let informativityID = project?.informativityID
        let informativityName = informativities.find((i) => i.id == project?.informativityID)?.name
        if(!authService.get().permissions.find((p) => p === Permission.deleteClinics)){


            let informativity: Informativity = {
                id: informativityID || '',
                name: nameInformativity || informativityName || '',
                clinicid: authService.get().clinics[0],
                projectid: project?.id,
                pgtype: 'pgsec',
            }
            individualService.updateInformativity(informativity).subscribe((res) => {
                setModalSaveInformativityOpened(false)
            })}else{
                patientService.getByID(project?.patientID || '').subscribe((res) => {
                    
                    let informativity: Informativity = {
                        id: informativityID || '',
                        name: nameInformativity || informativityName || '',
                        clinicid: res?.clinicID,
                        projectid: project?.id,
                        pgtype: 'pgsec',
                    }
                    individualService.updateInformativity(informativity).subscribe((res) => {
                        setModalSaveInformativityOpened(false)
                    })
                })}
        handleContinue()
    }

    const handleNameInformativity = (name: string, value: string) => {
        setNameInformativity(value)
    }

    const closeModalInformativity = () => {
        setModalSaveInformativityOpened(false)
    }

    const actionReplaceModalInformativity: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalInformativity,
        },
    ]

    const closeModalChildGender = () => {
        setModalChildGender(false)
    }

    const closeModalChildCarrierAffected = () => {
        setModalChildOriginAffected(false)
    }

    const actionReplaceModalChildGender: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalChildGender,
        },
    ]

    const actionReplaceModalCarrierAfected: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalChildCarrierAffected,
        },
    ]

    const refreshInformativities = () => {
         // new QueryParam('projectid' , project?.id || '')  
        //si es admin no se filtra por clinicid
            
            individualService.getInformativityList(new Query({query:  [new QueryParam('projectid' , project?.id || ''),
                new QueryParam('pgtype' , 'pgsec')]})).subscribe((res) => {
          
            setInformativities(res.items)
        })
    }

    useEffect(() => {
        refreshInformativities()
    }, [])

    useEffect(() => {
        if (!isLoading) return
        individualService
            .getFilteredList(
                new Query({
                    query: [new QueryParam('informativityID', props.project?.informativityID || '')],
                })
            )
            .subscribe((res) => {
                res.items.map(i => {
                    if(i.individual === Individuals.MALE) setFatherStatus(i.status)
                    if(i.individual === Individuals.FEMALE) setMotherStatus(i.status)

                })
                setIndividuals(res.items)
                setIsLoading(false)
                setShowSpinner(false)
                setIsLoadingBamFiles(true)
            })
            
    }, [isLoading])

    const fields: Field<any>[] = [
        {
            sortable: false,
            searchable: false,
            label: t('individual'),
            name: 'individual',
        },
        {
            sortable: false,
            searchable: false,
            label: t('fileName'),
            name: 'fileName',
        },
        {
            sortable: false,
            searchable: false,
            label: t('status'),
            name: 'status',
        },
        {
            sortable: false,
            searchable: false,
            label: t('mutation'),
            name: 'mutation',
        },
        {
            sortable: false,
            searchable: false,
            label: t('color'),
            name: 'color',
        },
    ]

    const fieldsFiles: Field<File2>[] = [
        {
            sortable: false,
            searchable: false,
            label: t('name'),
            name: 'name',
        },
        {
            sortable: false,
            searchable: false,
            label: t('type'),
            name: 'extension',
        },
        {
            sortable: false,
            searchable: false,
            label: t('individual'),
            name: 'individual',
            renderFunc: (f, item) => individualsTitle()[item.individual],
        },
    ]

    const changeFile = (f: File) => {
        setLastAdded(Object.assign({...lastAdded}, {file: f}))
        fileInputRef.current?.click()
    }

    const changeFileField = (f: File2) => {
        // Llamar al input para seleccionar ambos archivos (bam y bai)
        if (fileInputRefBAMBAI.current) {
            setCurrentFileIndividual(f.individual)
            fileInputRefBAMBAI.current.click();
        }
    }

    const actions: Actions<any> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: changeFile,
                icon: uploadIcon,
                label: t('upload'),
            },
        ],
    }

    const actionsFiles: Actions<File2> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: (item: File2) => changeFileField(item),
                icon: uploadIcon,
                label: t('upload'),
            },
        ],
    }

    const handleChange = (name: string, value: string | Number) => {
        setLastAdded(Object.assign({...lastAdded}, {[name]: value}))
    }

    const convertToFile = (f: File, data: string): FileDTO => ({
        id: uuidv4(),
        name: f.name,
        data,
        size: f.size,
        mimeType: f.type,
        extension: f.type.split('/')[1],
        ownerID: authService.get().id,
        pgType: ''
    })

    const convertToFile2 = (f: File): FileDTO => ({
        id: uuidv4(),
        name: f.name,
        data: f,
        size: f.size,
        mimeType: f.type,
        extension: f.type.split('/')[1],
        ownerID: authService.get().id,
        pgType: ''
    })

    const toFormData = (f: File | Blob, id: string, extension: string) => {
        const formData = new FormData();
    
        // Verificar si f es un Blob y convertirlo a File si es necesario
        let file: File;
    
        if (f instanceof Blob && !(f instanceof File)) {
            // Convertir Blob a File
            file = new File([f], 'nombre_archivo.bam', { type: f.type || 'application/octet-stream' });
            console.log('El objeto original era un Blob, ahora es un File:', file);
        } else {
            // Si f ya es un File, usarlo directamente
            file = f as File;
        }
    
        // Agregar los datos al FormData
        formData.append('data', file, file.name); // Usar el File convertido o original
        formData.append('id', id);
        formData.append('name', file.name);
        formData.append('mimeType', file.type || 'application/octet-stream'); // Evitar valores vacíos
        formData.append('size', file.size.toString());
        formData.append('extension', extension);
        formData.append('ownerID', authService.get().id);

        formData.forEach((value, key) => {
            console.log(`${key}:`, value);
        });
        return formData;
    };
   

    const handleFileInput2 = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const fl = (event.target as HTMLInputElement).files
        if ((!fl?.length) || (fl[0].name.substring(fl[0].name.length - 5, fl[0].name.length) !== '.gvcf') ){
            setErr(t('fileMustHaveGvcfExtension'))
            setOpen(true)
            return
        }

       

        const data = await dataToBase64(fl[0])
        if (currentIndividual) {
            let file = new F(convertToFile(fl[0], data)).toDTO()
            file.extension = 'gvcf'
            //@ts-ignore
            currentIndividual.file = file
            currentIndividual.fileName = fl[0].name

            setShowSpinner(true)
            individualService.update(currentIndividual.toDTO()).subscribe(() => {
                setIsLoading(true)
            })
        }

        event.target.value = '';
    }

    
     


    const handleFileInput3 = async (
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        const fl = (event.target as HTMLInputElement).files;
        if (!fl?.length) {
            return;
        }

        let bamFile: File | null = null;
        let baiFile: File | null = null;

        // Valida los archivos seleccionados
        for (let i = 0; i < fl.length; i++) {
            const file = fl[i];
            if (file.name.endsWith('.bam')) {
                bamFile = file;
            } else if (file.name.endsWith('.bai')) {
                baiFile = file;
            }
        }

        // Procesar archivo .bam
        if (bamFile) {
            setIsLoadingBamFiles2(true);
            let bamDTO = new F(convertToFile2(bamFile)).toDTO();
            bamDTO.extension = 'bam';

            // Encuentra el índice del archivo .bam en la lista existente
            let bamFileIndex = bamFiles.findIndex(
                (item) => item.individual == currentFileIndividual && item.extension == 'bam'
            );

            let newBamFile: File2 = {
                id: bamDTO.id,
                name: bamDTO.name,
                data: bamDTO.data,
                size: bamDTO.size,
                mimeType: bamDTO.mimeType,
                extension: bamDTO.extension,
                ownerID: bamDTO.ownerID,
                individual: currentFileIndividual || Individuals.CHILD,
                pgType: ''
            };

            let auxBamFiles = [...bamFiles];
            auxBamFiles[bamFileIndex] = newBamFile;
            setBamFiles(auxBamFiles);
            
            // Llama al servicio correspondiente
            await fileService.addBIM2(toFormData(bamFile , bamDTO.id, bamDTO.extension))
            let individualsAux = individuals.filter(
                (f) => f.individual == currentFileIndividual
            );
            if (individualsAux.length > 0) {
                let individual = individualsAux[0];
                individual.bimFileID = bamDTO.id;

                let updatedIndividuals = individuals.map(i => 
                    i.individual == individual.individual ? individual : i
                );
                
                setIndividuals(updatedIndividuals);
       

                individualService.updateBamFiles(individual.toDTO());
                refreshData()
            }

        }

        // Procesar archivo .bai
        if (baiFile) {
            setIsLoadingBamFiles2(true);
            let baiDTO = new F(convertToFile2(baiFile)).toDTO();
            baiDTO.extension = 'bai';

            // Encuentra el índice del archivo .bai en la lista existente
            let baiFileIndex = bamFiles.findIndex(
                (item) => item.individual == currentFileIndividual && item.extension == 'bai'
            );

            let newBaiFile: File2 = {
                id: baiDTO.id,
                name: baiDTO.name,
                data: baiDTO.data,
                size: baiDTO.size,
                mimeType: baiDTO.mimeType,
                extension: baiDTO.extension,
                ownerID: baiDTO.ownerID,
                individual: currentFileIndividual || Individuals.CHILD,
                pgType: ''
            };

            let auxBamFiles = [...bamFiles];
            auxBamFiles[baiFileIndex] = newBaiFile;
            setBamFiles(auxBamFiles);

            // Llama al servicio correspondiente
            await fileService.addBAI2(toFormData(baiFile , baiDTO.id, baiDTO.extension))

            let individualsAux = individuals.filter(
                (f) => f.individual == currentFileIndividual
            );
            if (individualsAux.length > 0) {
                let individual = individualsAux[0];
                individual.baiFileID = baiDTO.id;

              
                let updatedIndividuals = individuals.map(i => 
                    i.individual == individual.individual ? individual : i
                );
                
                setIndividuals(updatedIndividuals);
                

                individualService.updateBamFiles(individual.toDTO());
                refreshData()
            }

            setIsLoadingBamFiles2(false);
        }

        // Limpiar el input de archivos
        event.target.value = '';
    };


    const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const fl = (event.target as HTMLInputElement).files
        if ((!fl?.length) || (fl[0].name.substring(fl[0].name.length - 5, fl[0].name.length) !== '.gvcf') ){
            setErr(t('fileMustHaveGvcfExtension'))
            setOpen(true)
            return
        }

      

        const data = await dataToBase64(fl[0])
        let file = new F(convertToFile(fl[0], data)).toDTO()
        file.extension = 'gvcf'

        let newIndividual = Object.assign(
            {...lastAdded},
            {file: file},
            //@ts-ignore
            {individual: lastAdded.individual},
            {fileName: fl[0].name},
            {informativityID: project?.informativityID},
            {projectID: project?.id}
        )

        if (newIndividual.individual === Individuals.FEMALE) setMotherStatus(newIndividual.status)
        if (newIndividual.individual === Individuals.MALE) setFatherStatus(newIndividual.status)
    
        if (lastAdded.individual === Individuals.CHILD) {
            setChildIndividual(lastAdded)
            setExistChild(true)
            setModalChildGender(true)
        }

        setShowSpinner(true)
        individualService.add(newIndividual).subscribe(() => {
            setLastAdded(emptyIndividualDTO(props.project?.id || ''))
            refreshInformativities()
            setIsLoading(true)
        })

        event.target.value = '';
    }

    const [existChild, setExistChild] = useState<boolean>(false)

    const getColors = (individualType: Individuals) => {
        let colors = []

        switch (individualType) {
            case Individuals.FEMALE_FATHER:
                colors.push('rgb(3, 222, 108)')
                colors.push('rgb(221, 234, 251)')
                break
            case Individuals.FEMALE:
                colors.push('rgb(237, 106, 250)')
                colors.push('rgb(3, 222, 108)')
                break
            case Individuals.MALE:
                colors.push('#ffd853')
                colors.push('#25e6e9')
                break
            case Individuals.CHILD:
                colors.push('rgb(237, 106, 250)')
                colors.push('#ffd853')
                break
            case Individuals.FEMALE_MOTHER:
                colors.push('rgb(237, 106, 250)')
                colors.push('rgb(221, 234, 251)')
                break
            case Individuals.MALE_MOTHER:
                colors.push('rgb(232, 216, 99)')
                colors.push('rgb(221, 234, 251)')
                break
            case Individuals.MALE_FATHER:
                colors.push('rgb(9, 214, 232)')
                colors.push('rgb(221, 234, 251)')
                break
            default:
                break
        }

        return colors
    }

    const deleteIndividual = (ind: Individual) => {
        setCurrentIndividual(ind)
        setIsViewData(true)
        setModalDeleteOpened(true)
    }

    const deleteConfirmed = (event: any) => {
        event.preventDefault()
        setShowSpinner(true)
        if (currentIndividual?.individual == Individuals.CHILD) {
            setExistChild(false)
            let projectToSave = convertToProjectDTO(project as ProjectAnalysis)
            projectToSave.childGender = ChildGenderPGD.Unknown
            projectToSave.resultTableID = "00000000-0000-0000-0000-000000000000"
            projectService.update(projectToSave).subscribe((res) => {
            })
        }
        individualService.delete(currentIndividual?.id || '').subscribe(() => {
            setModalDeleteOpened(false)
            setIsLoading(true)
            setLastAdded(emptyIndividualDTO(props.project?.id || ''))
        })
    }

    const closeModal = () => {
        setModalDeleteOpened(false)
        setCurrentIndividual(undefined)
    }

    const closeModalReplace = () => {
        setModalReplaceOpened(false)
        setCurrentIndividual(undefined)
    }

    const actionsDelete: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModal,
        },
    ]

    const actionsReplace: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalReplace,
        },
    ]

    const replaceConfirmed = (event: any) => {
        event.preventDefault()
        setModalReplaceOpened(false)
        fileInputRef2.current?.click()
    }

    const handleContinue = () => {
        if (individuals.length > 0) {
            props.increaseStep()
        }
    }


    const handleChangeInformativity = (e: string) => {
        if (project) {
            
            if (e == undefined) {
                setIndividuals([])
                let auxProject = Object.assign({...project}, {informativityID: uuidv4(), resultTableID: nullId, parentsFasadeID: nullId, resultTableWithEmbryosID: nullId })
                setProject(auxProject)
                props.setProject(auxProject)
                projectService.update(auxProject)
                informativities.forEach((group) => {
                    if(group.id === e) group.projectid = project.id
                    else group.projectid = nullId
                    individualService.updateInformativity(group)
                })
                return
            }
            let auxProject = Object.assign({...project}, {informativityID: e, resultTableID: nullId, resultTableWithEmbryosID: nullId })
            setProject(auxProject)
            props.setProject(auxProject)

            setShowSpinner(true)
            projectService.update(auxProject).subscribe((res) => {
                setIsLoading(true)
            })  

            informativities.forEach((group) => {
                if(group.id === e) group.projectid = project.id
                else group.projectid = nullId
                individualService.updateInformativity(group)
            })
        }
    }
    const [modalChildGender, setModalChildGender] = useState<boolean>(false)
    const [childGender, setChildGender] = useState<ChildGenderPGD>(3)

    const replacedChildGender = (event: number) => {
        setChildGender(parseInt(event.toString(), 10))
    }

    const replacedChildFinalStatus = (event: FinalStatus) => {
        setChildFinalStatus(parseInt(event.toString(), 10))
    }

    const updateChildGender = (event?: React.FormEvent<HTMLFormElement>)  => {
        event?.preventDefault();
        if (props.project) {
            let projectToSave = convertToProjectDTO(project as ProjectAnalysis)
            projectToSave.childGender = childGender
            projectService.update(projectToSave).subscribe((res) => {
                setModalChildGender(false)
            })
        }
    }

    const updateChildCarrier = (event?: React.FormEvent<HTMLFormElement>)=> {
            event?.preventDefault();

            if (childIndividual ){
                if( childFinalStatus) childIndividual.finalStatus = childFinalStatus
            let individualsAux = individuals.filter(
                (f) => f.individual == childIndividual?.individual
            );
            if (individualsAux.length > 0) {
                let individual = individualsAux[0];
                individual.finalStatus = childIndividual.finalStatus;

                let updatedIndividuals = individuals.map(i => 
                    i.individual == individual.individual ? individual : i
                );
                
                setIndividuals(updatedIndividuals);
       
                individualService.update2(individual.toDTO()).subscribe(() => {
                    setModalChildOriginAffected(false)
                    runParentsSnipetsProcess()
                })
       
            }}

   
    }

    const checkContainsRequiredIndividuals = (individuals: Individual[]) => {
        const hasFemale = individuals.some(ind => ind.individual === Individuals.FEMALE);
        const hasMale = individuals.some(ind => ind.individual === Individuals.MALE);

        // Filtramos los individuos para verificar que hay alguno más que no sea FEMALE o MALE
        const others = individuals.filter(ind =>
            ind.individual !== Individuals.FEMALE &&
            ind.individual !== Individuals.MALE
        );

        return hasFemale && hasMale && others.length > 0;
    }
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    const snackbarFunction = () => (
        <div>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            style={{
                position: 'absolute',
                left: '50%',
                marginLeft: 'auto',
            }}>
            <Alert
                onClose={handleClose}
                severity={'error'}
                style={{
                    marginLeft: 'initial',
                }}>
                {err}
            </Alert>
        </Snackbar>
    </div>
    )

    const checkChildCarrier = () => {
        project?.pathology === "Recessive" && 
            fatherStatus === Status.Carrier && motherStatus === Status.Carrier &&
            childIndividual?.status === Status.Carrier? 
            setModalChildOriginAffected(true) : runParentsSnipetsProcess()
        
    }

    const runParentsSnipetsProcess = () => {
        if (!checkContainsRequiredIndividuals(individuals)) {
            setOpen(true)
            return
        }
        setModalPreviewOpened(true)
        setDataLoaded(false)
        individualService.runParentsSnipetsProcess(project?.id || '').subscribe((res: any) => {
            let auxRes = res
            // let auxRes2
            if (res) {
                auxRes.preProcessedData = auxRes.preProcessedData.map((item: any) => ({
                    ...item,
                    seeEmbryo: true,
                }))
                // project?.pathology === "Recessive"? auxRes2 = calculateCarrierChild(auxRes) : auxRes2 = res
            }
            setIsLoading(true)
            setDataLoaded(true)
            setIsViewData(false)
            props.setData(auxRes)
            props.setIsViewResult(true)
        })
    }

    useEffect(() => {
        if (project?.resultTableID != "00000000-0000-0000-0000-000000000000") {
            setIsViewData(false)
        }
    }, [project]);

    useEffect(()=>{
        var countPatient: InformativeSNP = {
            upstream: 0,
            intrangenic: 0,
            downstream: 0
        }

        var countPartner: InformativeSNP = {
            upstream: 0,
            intrangenic: 0,
            downstream: 0
        }
        var y = 0
        const dataAux = data?.resultTableData
        if(project !== undefined){
             for (let i =0 ; i < dataAux?.length ; i++){
                if (dataAux[i]?.chromosoma === project?.chr){
                    if(dataAux[i]?.position >= project?.firstSNP && dataAux[i]?.position <= project?.firstGene) {
                        if(dataAux[i]?.individualType === "father_info") countPatient.upstream++
                        if(dataAux[i]?.individualType === "mother_info") countPartner.upstream++

                    }
                    if(dataAux[i]?.position >= project?.firstGene && dataAux[i]?.position <= project?.lastGene) {
                        if(dataAux[i]?.individualType === "father_info") countPatient.intrangenic++
                        if(dataAux[i]?.individualType === "mother_info") countPartner.intrangenic++

                    }
                    if(dataAux[i]?.position >= project?.lastGene && dataAux[i]?.position <= project?.lastSNP) {
                        if(dataAux[i]?.individualType === "father_info") countPatient.downstream++
                        if(dataAux[i]?.individualType === "mother_info") countPartner.downstream++

                    }
                }
            }}
        
            props.patientInf(countPatient)
            props.partnerInf(countPartner)

    }, [data])

    const [modalIGVOpened, setModalIGVOpened] = useState<boolean>(false)

    const openModalIGV = () => {
        setModalIGVOpened(true)
    }

    const closeIGVModal = () => {
        setModalIGVOpened(false)
    }

    return (
        <>
            <Typography
                style={{
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: 'rgb(125, 191, 220)',
                    marginBottom: '0.5%',
                }}
                align="left">
                {t('informativity').toLocaleUpperCase() } - {projectName}
                </Typography>
            <Divider style={{marginBottom: '2%', width: '100%'}}/>
            <Grid item xs={12} style={{marginBottom: '2.5%'}}>
                <Grid item xs={4} style={{marginBottom: '3%'}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="selectInformativity">{t('selectInformativity')}</InputLabel>
                        <Select
                            labelId="selectInformativity"
                            id="selectInformativity"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={project?.informativityID}
                            onChange={(e) => e && handleChangeInformativity(e.target.value as string)}
                            label={t('selectInformativity')}>
                            {informativities
                                .filter((l) => l.name)
                                .map((l, i) => (
                                    <MenuItem value={l.id} key={l.id}>
                                        {l.name || l.id}
                                    </MenuItem>
                                ))}
                            <MenuItem value={undefined} key={undefined}>
                                {t('doNotSelect')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {!isLoading && !showSpinner ? (
                    <TableContainer>
                        <Table>
                            <Head
                                fields={fields}
                                styleHeader={{fontWeight: 'bold', color: 'rgb(125, 191, 220)'}}
                                actions={actions}
                            />
                            <Body actions={actions} fields={fields} items={[]} rowKeyField={DEFAULT_ROW_KEY}/>
                            {individuals.map((ind, index) => (
                                <TableRow>
                                    <TableCell key="individual" className={styles.selectCell}>
                                        {individualsTitle()[ind.individual]}
                                    </TableCell>
                                    <TableCell key="fileName">{ind.fileName}</TableCell>

                                    <TableCell key="status" className={styles.selectCell}>
                                        {statusTitle()[ind.status]}
                                    </TableCell>
                                    <TableCell key="mutation">{ind.mutation !== '' ? ind.mutation : '-'}</TableCell>
                                    <TableCell key="color">
                                        <div className={styles.row}>
                                            {getColors(ind.individual)?.map((c) => (
                                                <div className={styles.colorsRow} style={{backgroundColor: c}}/>
                                            ))}
                                        </div>
                                    </TableCell>
                                    <TableCell key="actions">
                                        <Box display="flex" justifyContent="space-between">
                                            <GenericTooltip
                                                values={[t('delete')]}
                                                icon={
                                                    <img
                                                        src={deleteIcon}
                                                        alt=''
                                                        className={styles.imgAction}
                                                        onClick={() => deleteIndividual(ind)}
                                                    />
                                                }
                                                noUseFab={true}
                                            />
                                            <GenericTooltip
                                                values={[t('download2')]}
                                                icon={
                                                    <img
                                                        src={downloadIcon}
                                                        alt=''
                                                        className={styles.imgAction}
                                                        onClick={() =>
                                                            downloadFile(
                                                                ind.file?.name || '',
                                                                ind.file?.mimeType || '',
                                                                ind.file?.data || ''
                                                            )
                                                        }
                                                    />
                                                }
                                                noUseFab={true}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell key="individual" className={styles.selectCell}>
                                    <Select
                                        className={styles.select}
                                        variant="outlined"
                                        value={lastAdded.individual}
                                        onChange={(e: any) => handleChange('individual', Number(e.target.value))}>
                                        {individualOptions
                                            .filter((i) => !individuals.map((i) => i.individual).includes(i))
                                            .map((l) => (
                                                <MenuItem value={l} key={l} selected={l == lastAdded.individual}>
                                                    {/**@ts-ignore */}
                                                    {individualsTitle()[l]}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </TableCell>
                                <TableCell key="fileName">{lastAdded.fileName}</TableCell>

                                <TableCell key="status" className={styles.selectCell}>
                                <Select
                                        className={styles.select}
                                        variant="outlined"
                                        value={lastAdded.status}
                                        onChange={(e: any) => handleChange('status', e.target.value)}>
                                        {statusOptions.map((l) =>  {
                                             if (!(project?.pathology === 'X-Linked' && (lastAdded.individual === 0 || lastAdded.individual ===2 || lastAdded.individual ===6) && statusTitle()[l]==="Carrier" )
                                              && !(project?.pathology === 'Dominant' && statusTitle()[l]==="Carrier" )){
                                                return(
                                                <MenuItem value={l} key={l} selected={l == lastAdded.status}>
                                                {statusTitle()[l]}
                                                </MenuItem>)
                                            }}
                                        )}
                                    </Select>
                                </TableCell>
                                <TableCell key="mutation">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={1}
                                        value={lastAdded.mutation}
                                        onChange={(e: any) => handleChange('mutation', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell key="color">
                                    <div className={styles.row}>
                                        {getColors(lastAdded.individual)?.map((c) => (
                                            <div className={styles.colorsRow} style={{backgroundColor: c}}/>
                                        ))}
                                    </div>
                                </TableCell>
                                <TableCell key="actions">
                                    {lastAdded.individual != Individuals.UNKNOWN &&
                                    lastAdded.status != Status.Null ? (
                                        <Box display="flex" justifyContent="space-between">
                                            {actions.items.map((a) => {
                                                return (
                                                    <GenericTooltip
                                                        values={[t(a.label || a.icon)]}
                                                        icon={
                                                            <img
                                                                src={a.icon}
                                                                alt=''
                                                                className={styles.imgAction}
                                                                onClick={() => a.handler(lastAdded)}
                                                            />
                                                        }
                                                        noUseFab={true}
                                                    />
                                                )
                                            })}
                                        </Box>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box>
                        <CircularProgress/>
                    </Box>
                )}

                {(!isLoadingBamFiles && !isLoadingBamFiles2) ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>} // Icono para expandir el acordeón
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography
                                style={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    color: 'rgb(125, 191, 220)',
                                    marginBottom: '0.5%',
                                    marginTop: '5%',
                                }}
                                align="left"
                            >
                                {t('bamFiles').toLocaleUpperCase()}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AppTable
                                styleHeader={{color: COLOR_CLINICS}}
                                actions={actionsFiles}
                                fields={fieldsFiles}
                                items={bamFiles}
                                rowKeyField={'id'}
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <Box>
                        <CircularProgress/>
                    </Box>
                )}


                <input
                    onChange={(event) => handleFileInput(event)}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                />
                <input
                    onChange={(event) => handleFileInput2(event)}
                    multiple={false}
                    ref={fileInputRef2}
                    type="file"
                    hidden
                />
                {/*<input
                    onChange={(event) => handleFileInput3(event, 'bai')}
                    multiple={false}
                    ref={fileInputRefBAI}
                    type="file"
                    hidden
                    accept=".bai"
                />*/}
                <input
                    onChange={(event) => handleFileInput3(event)}
                    multiple={true}
                    ref={fileInputRefBAMBAI}
                    type="file"
                    hidden
                    accept=".bam,.bai"
                />

                <Grid item xs={12} className={styles.backButtonGrid} style={{marginTop: '3%'}}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBack/>}
                        onClick={props.decreaseStep}
                        className={styles2.button}
                        style={{display: 'flex', alignSelf: 'flex-start'}}>
                        {t('back2')}
                    </Button>
                        <Button
                            variant="contained"
                            onClick={checkChildCarrier}
                            className={styles2.button}
                            style={{display: 'flex', alignSelf: 'flex-start'}}>
                            {t('runProcess')}
                        </Button>
                    <Button
                        variant="contained"
                        onClick={openModalIGV}
                        className={styles2.button}
                        style={{display: 'flex', alignSelf: 'flex-start'}}>
                        {t('igvButton')}
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<ArrowForward/>}
                        onClick={openSaveInformativityModal}
                        className={styles2.button}
                        disabled={isViewData}
                        style={{display: 'flex', alignSelf: 'flex-start'}}>
                        {t('continue')}
                    </Button>
                </Grid>
            {snackbarFunction()}

            </Grid>

            <Modal open={modalDeleteOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={deleteConfirmed}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('individualConfirmationMessage')}
                        </Box>
                        <FormActions actions={actionsDelete} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

            <Modal open={modalReplaceOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={replaceConfirmed}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('individualReplaceConfirmationMessage')}
                        </Box>
                        <FormActions actions={actionsReplace} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

            <Modal open={modalIGVOpened}>
                <Box className={styles2.modalIGV} style={{ textAlign: 'center' }}>
                    <IGV project={props.project} />
                    <Button
                        variant="contained"
                        onClick={closeIGVModal}
                        className={styles2.button}
                        style={{ marginBottom: '20px' }}
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Modal>


            <Modal open={modalChildGender}>
                <Box className={styles2.modal}>
                    <form onSubmit={updateChildGender}>
                            <Box mt={2} textAlign={'justify'}>
                                {t('selectChildGender')}
                            </Box>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="inherit-label">{t('childGender')}</InputLabel>
                                <Select
                                    labelId="childGender"
                                    id="childGender"
                                    fullWidth
                                    style={{textAlign: 'left'}}
                                    value={(data && data.childGender) || childGender}
                                    onChange={(event) => replacedChildGender(event.target.value as number)}
                                    label={t('childGender')}>
                                    {Object.entries(childGendersPGD())
                                        .filter(([k, v]) => v !== 'Unknown')
                                        .map(([key, value]) => {
                                            if (!(project?.pathology === 'X-Linked' && childIndividual?.status === 1 && value === t('Male') )) {return <MenuItem value={key}>{value}</MenuItem>}
                                        })}
                                </Select>
                                
                            </FormControl>
                        <FormActions actions={actionReplaceModalChildGender} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

            <Modal open={modalChildOriginAffected}>
                <Box className={styles2.modal}>
                    <form onSubmit={updateChildCarrier}>

                            <Box mt={2} textAlign={'justify'}>
                                {t('selecOriginAffected')}
                            </Box>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="inherit-label">{t('originAffected')}</InputLabel>
                                <Select
                                    labelId="originAffected"
                                    id="originAffected"
                                    fullWidth
                                    style={{textAlign: 'left'}}
                                    value={(childFinalStatus && childFinalStatus) || childIndividual?.finalStatus}
                                    onChange={(event) => replacedChildFinalStatus(event.target.value as FinalStatus)}
                                    label={t('originAffected')}>
                                        <MenuItem value={FinalStatus.CarrierFromMother}>{"Carrier from mother"}</MenuItem>
                                        <MenuItem value={FinalStatus.CarrierFromFather}>{"Carrier from father"}</MenuItem>

                                </Select>
                                
                            </FormControl>
                        <FormActions actions={actionReplaceModalCarrierAfected} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

            {!dataLoaded && (
                <Modal open={!dataLoaded && modalPreviewOpened}>
                    <div className={styles.modalLoading}>
                        <CircularProgress className={styles.circularProgress}/>
                    </div>
                </Modal>
            )}

            <Modal open={modalSaveInformativityOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={saveInformativity}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('informativityNameModalMessage')}
                        </Box>
                        <Box mt={2} textAlign={'justify'}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                id={'name'}
                                type={'string'}
                                onChange={(event) => handleNameInformativity('name', event.target.value)}
                                value={nameInformativity || ''}
                                label={t('informativityName')}
                            />
                        </Box>
                        <FormActions actions={actionReplaceModalInformativity} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

           
        </>
    )
}
